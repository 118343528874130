
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.befar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* .MuiTable-root td, .MuiTable-root th {
  width: auto !important;
  min-width: auto;
} */

.navbar-custom {
  z-index: 999;
  background: #fff;
}

.customMODEL {
  padding: 1.56rem;
}

.customMODEL h2 {
  text-align: center;
}

.MuiPaper-root {
width: 100%;
}
.card-Active {
  margin-bottom: 24px;
  background-color: aquamarine;
}
.card-body1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  background: mistyrose;
}

.app-menu .menu-item .menu-link .active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  list-style: none;
  position: relative;
  color: var(--bs-menu-item-color);
  gap: var(--bs-menu-link-item-gap);
  -webkit-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  border-radius: var(--bs-border-radius);
  font-size: var(--bs-menu-item-font-size);
  padding: var(--bs-menu-link-padding-y) var(--bs-menu-link-padding-x);
  background-color: gainsboro;
}



@media(min-width: 1201px){
  .page-content{
    width: calc(100% - 240px);
  }
}

h2, a{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.section{
  padding: 4rem 2rem;
  text-align: center;
}

.section .error{
  font-size: 150px;
  color: #008B62;
  text-shadow: 
    1px 1px 1px #00593E,    
    2px 2px 1px #00593E,
    3px 3px 1px #00593E,
    4px 4px 1px #00593E,
    5px 5px 1px #00593E,
    6px 6px 1px #00593E,
    7px 7px 1px #00593E,
    8px 8px 1px #00593E,
    25px 25px 8px rgba(0,0,0, 0.2);
}

.page{
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home{
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
  background: #222;
  border-radius: 6px;
}
.back-home:hover{
  background: #222;
  color: #ddd;
}